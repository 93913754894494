import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { StorageService } from '@shared/services/storage.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private subscription: Subscription = Subscription.EMPTY;

  constructor(
    private translateService: TranslocoService,
    private storageService: StorageService
  ) {}

  get activeLang() {
    return this.translateService.getActiveLang();
  }

  public changeLanguage(lang: string): void {
    this.subscription.unsubscribe();
    this.subscription = this.translateService
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.translateService.setActiveLang(lang);
        this.storageService.setItem('language', lang);
      });
  }

  public getCurrentLanguage(): string {
    return this.storageService.getItem('language') || 'en';
  }
}
