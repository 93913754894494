import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({ providedIn: 'root' })
export class GtmService {
  constructor(
    @Inject(DOCUMENT) private injectedDocument: Document,
    private rendererFactory: RendererFactory2
  ) {
    window.dataLayer = window.dataLayer || [];
  }

  public addGtmScript(gtmId: string): void {
    if (gtmId) {
      const gtmCode = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;

      const renderer = this.rendererFactory.createRenderer(null, null);

      // Add GTM script
      const scriptElement = renderer.createElement('script');
      renderer.setAttribute(scriptElement, 'id', 'gtm-script');
      renderer.setAttribute(scriptElement, 'type', 'text/javascript');
      renderer.setProperty(scriptElement, 'innerHTML', gtmCode);
      renderer.appendChild(this.injectedDocument.head, scriptElement);

      // Add noscript fallback
      const noScriptElement = renderer.createElement('noscript');
      const iframe = renderer.createElement('iframe');
      renderer.setAttribute(iframe, 'src', `https://www.googletagmanager.com/ns.html?id=${gtmId}`);
      renderer.setAttribute(iframe, 'height', '0');
      renderer.setAttribute(iframe, 'width', '0');
      renderer.setAttribute(iframe, 'style', 'display:none;visibility:hidden;');
      renderer.appendChild(noScriptElement, iframe);

      // Ensure noscript is inserted correctly
      if (this.injectedDocument.body.firstChild) {
        renderer.insertBefore(this.injectedDocument.body, noScriptElement, this.injectedDocument.body.firstChild);
      } else {
        renderer.appendChild(this.injectedDocument.body, noScriptElement);
      }
    } else {
      console.warn("GTM ID for Google Tag Manager isn't provided");
    }
  }

  public pushEvent(eventData: any): void {
    if (window && (window as any).dataLayer) {
      (window as any).dataLayer.push(eventData);
    } else {
      console.warn('GTM Data Layer not found');
    }
  }

  public trackPageView(url: string): void {
    if (window && (window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: 'pageView',
        pagePath: url,
      });
    } else {
      console.warn('GTM Data Layer not found');
    }
  }

  public trackEvent(eventName: string, eventData: Record<string, any>): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    });
  }
}
