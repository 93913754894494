<button
  class="green-btn"
  [ngClass]="{ 'white-bg': whiteBg && !active, disabled: disabled }"
  [style.font-weight]="buttonProps && buttonProps.fontWeight"
  [style.font-size]="buttonProps && buttonProps.fontSize"
  [style.width]="buttonProps && buttonProps.width"
  [style.backgroundColor]="buttonProps && buttonProps.backgroundColor"
  [style.color]="buttonProps && buttonProps.color">
  <ng-content></ng-content>
</button>
