import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailConfirmedComponent } from './email-confirmed.component';
import { GreenBtnModule } from '@shared/modules/green-btn/green-btn.module';

const routes: Routes = [
  { path: '', component: EmailConfirmedComponent }
];

@NgModule({
  declarations: [EmailConfirmedComponent],
  imports: [
    RouterModule.forChild(routes),
    GreenBtnModule
  ],
  exports: [RouterModule]
})
export class EmailConfirmedModule { }
