<div class="header-wrapper" [ngClass]="{ 'menu-open': mobileMenuVisible }">
  <div class="burger-menu" (click)="toggleMenu()">
    <div class="burger-icon">
      <input type="checkbox" />
      <svg>
        <use xlink:href="#menu" />
        <use xlink:href="#menu" />
      </svg>
    </div>
    <!-- SVG -->
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 56" id="menu">
        <path d="M48.33,45.6H18a14.17,14.17,0,0,1,0-28.34H78.86a17.37,17.37,0,0,1,0,34.74H42.33l-21-21.26L47.75,4" />
      </symbol>
    </svg>
  </div>
  <div [routerLink]="''">
    <img class="logo" src="assets/images/logo.svg" alt="logo" height="35" width="132" />
    <div class="profile" *ngIf="profile">{{ profile }}</div>
  </div>
  <div class="menu-title">{{ 'app.header.menu' | transloco }}</div>
  <div class="header-container">
    <!--    <div *ngIf="userRole$ | async as userRole" class="user-state">-->
    <!--      <span >{{ userRole }}</span>-->
    <!--      <label class="switch">-->
    <!--        <input type="checkbox" [ngModel]="userRole == 'User'" (ngModelChange)="changeUserState(userRole)">-->
    <!--        <span class="slider"></span>-->
    <!--    </label>-->
    <!--    </div>-->

    <ng-container *ngFor="let data of headerData">
      <ng-container *ngIf="data.name !== 'Country' && data.name !== 'Language'">
        <div
          [ngClass]="removeSpaces(data.name)"
          class="headerData flex-row"
          *ngIf="data.isVisible"
          (click)="handleClick(data.name)"
          [routerLink]="[data.routerLink]">
          <img *ngIf="data?.icon" [src]="data?.icon" alt="icon" />
          <span *ngIf="data.name !== 'Language'">{{ data.label | transloco }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="data.name === 'Language'">
        <div [ngClass]="removeSpaces(data.name)" class="headerData flex-row" *ngIf="data.isVisible">
          <span *ngIf="data.name === 'Language'">{{ language | uppercase }}</span>
          <div class="language-dropdown" *ngIf="data.name === 'Language'">
            <div class="buttons">
              <button
                (click)="changeLanguage('en')"
                class="language-dropdown-btn"
                [ngClass]="{ active: language === 'en' }">
                <img src="/assets/icons/us-flag.svg" alt="flag" />
                <span>English</span>
              </button>
              <button
                (click)="changeLanguage('ua')"
                class="language-dropdown-btn"
                [ngClass]="{ active: language === 'ua' }">
                <img src="/assets/icons/ua-flag.svg" alt="flag" />
                <span>Українська</span>
              </button>
              <button
                (click)="changeLanguage('pl')"
                class="language-dropdown-btn"
                [ngClass]="{ active: language === 'pl' }">
                <img src="/assets/icons/pl-flag.svg" alt="flag" />
                <span>Polska</span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!--    <button>Help me</button>-->
  </div>
</div>

<div class="mobile-menu" *ngIf="mobileMenuVisible">
  <nav>
    <a *ngIf="!userIsLogged" [routerLink]="'/login'" (click)="hideMenu()">Login</a>
    <a *ngIf="!userIsLogged" [routerLink]="'/auth'" (click)="hideMenu()">Sign Up</a>
    <a *ngIf="userIsLogged" (click)="handleClick('Log out')">Log out</a>
  </nav>
</div>
