import { Component, Input } from '@angular/core';

export interface IGreenBtn {
  width: string;
  height: string;
  fontWeight: number;
  fontSize: string;
  backgroundColor: string;
  color: string;
}

@Component({
  selector: 'green-btn',
  templateUrl: './green-btn.component.html',
  styleUrls: ['./green-btn.component.scss'],
})
export class GreenBtnComponent {
  @Input() buttonProps!: Partial<IGreenBtn> | undefined;
  @Input() active!: boolean;
  @Input() public whiteBg = false;
  @Input() disabled = false;
}
