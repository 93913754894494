import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { TDateOrString } from '@shared/interfaces';
import { ICalendarSelectedDay } from '@shared/interfaces/calendar.interface';
import * as CalendarActions from './calendar.actions';

export interface ICalendarState {
  selectedDay: TDateOrString | null;
  selectedDays: ICalendarSelectedDay[] | [];
  isUpdating: boolean;
}

export const initialCalendarState: ICalendarState = {
  selectedDay: null,
  selectedDays: [],
  isUpdating: false,
};
export const calendarReducer = createReducer(
  initialCalendarState,
  on(CalendarActions.SetSelectedDay, (state): ICalendarState => ({ ...state, isUpdating: true })),
  on(
    CalendarActions.SetSelectedDays,
    (state, { selectedDays, selectedDay }): ICalendarState => ({
      ...state,
      selectedDays,
      selectedDay,
      isUpdating: false,
    })
  ),
  on(CalendarActions.ChangeSelectedDay, (state, { selectedDay }): ICalendarState => ({ ...state, selectedDay })),
  on(CalendarActions.ClearCalendarStoreAction, (): ICalendarState => ({ ...initialCalendarState })),
  on(CalendarActions.SetSelectedTime, (state, { selectedDays }): ICalendarState => ({ ...state, selectedDays }))
);

export const selectCalendar = createFeatureSelector<ICalendarState>('calendar');
export const selectCalendarSelectedDay = createSelector(selectCalendar, (state: ICalendarState) => {
  return { selectedDay: state.selectedDay };
});

export const selectCalendarSelectedDays = createSelector(selectCalendar, (state: ICalendarState) => {
  return { selectedDays: state.selectedDays };
});

export const selectUpdatingState = createSelector(selectCalendar, (state: ICalendarState) => {
  return { isUpdating: state.isUpdating };
});
