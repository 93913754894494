import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

type CorsProperties = {
  message: string;
  profile: string;
};

@Injectable({ providedIn: 'root' })
export class PingService {
  constructor(private http: HttpClient) {}

  public getPing(): Observable<CorsProperties> {
    return this.http.get<CorsProperties>('ping');
  }
}
