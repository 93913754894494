import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() outsideClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      document.addEventListener('click', this.onClick.bind(this));
    });
  }

  onClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.outsideClick.emit();
    }
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onClick.bind(this));
  }
}
