export const headerData = [
  {
    id: 1,
    routerLink: '',
    icon: 'assets/icons/country.png',
    name: 'Country',
    label: 'app.header.country',
    isVisible: true,
  },
  // {
  //   id: 2,
  //   icon: "assets/icons/login.png",
  //   label: "",
  //   nestedMenu: true,
  //   nestedMenuOpen: false,
  //   nestedMenuList: [
  //     // {
  //     //   name: "Log in",
  //     //   routerLink: "login",
  //     // },
  //     // {
  //     //   name: 'Sign Up',
  //     //   routerLink: "auth",
  //     // },
  //     // {
  //     //   name: 'User Profile',
  //     //   routerLink: "user-profile",
  //     // }
  //   ]
  // },
  // {
  {
    id: 2,
    name: 'Log in',
    label: 'app.header.login',
    routerLink: '/login',
    isVisible: true,
    checkAuth: true,
    showOnUserLogin: false,
  },
  {
    id: 3,
    name: 'Sign Up',
    label: 'app.header.signup',
    routerLink: '/auth',
    isVisible: true,
    checkAuth: true,
    showOnUserLogin: false,
  },
  {
    id: 4,
    name: 'User Profile',
    label: 'app.header.userProfile',
    routerLink: '/dashboard',
    isVisible: true,
    checkAuth: true,
    showOnUserLogin: true,
  },
  {
    id: 5,
    name: 'Log out',
    label: 'app.header.logout',
    routerLink: '',
    isVisible: true,
    checkAuth: true,
    showOnUserLogin: true,
  },
  {
    id: 6,
    name: 'Language',
    routerLink: '',
    label: 'app.header.language',
    isVisible: true,
  },
];
