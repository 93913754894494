import { Component } from '@angular/core';
import {FOOTER_MENU, FooterMenuI, SOCIAL_BUTTONS} from "@shared/modules/footer/footer.helper";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public footerMenu: FooterMenuI[] = FOOTER_MENU;
  public socialButtons = SOCIAL_BUTTONS;
}
