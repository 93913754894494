import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { defer, map, of, switchMap } from 'rxjs';

import { AuthService } from '@api/auth.service';
import { PingService } from '@api/ping.service';
import { IUserProfileResponse, UserApiService } from '@api/user-api.service';
import { UserService } from '@core/services/user.service';
import { LanguageService } from '@core/translate/language.service';
import { environment } from '@environments/environment';
import { headerData } from './header-data';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public headerData = headerData;
  public mobileMenuVisible = false;
  public userIsLogged = false;
  // // public user!: IBaseUser | null;
  public profile?: string = undefined;

  public region = 'Poland';
  public language: string | undefined;

  constructor(
    private authService: AuthService,
    private userApiService: UserApiService,
    private userService: UserService,
    private pingService: PingService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.language = this.languageService.getCurrentLanguage();
    this.languageService.changeLanguage(this.language);
    this.authService.loggedIn$
      .pipe(
        untilDestroyed(this),
        switchMap((isLogged: boolean) => {
          this.userIsLogged = isLogged;
          return defer(() =>
            isLogged
              ? this.userApiService.getUserInfo().pipe(
                  map((res: IUserProfileResponse) => {
                    this.userService.setUserData(res);
                    return isLogged;
                  })
                )
              : of(isLogged)
          );
        })
      )
      .subscribe((isLogged = false) => {
        this.headerData.forEach(
          el => el.checkAuth && (el.isVisible = isLogged ? el.showOnUserLogin : !el.showOnUserLogin)
        );
      });

    this.pingService.getPing().subscribe(({ branch: backendProfile }) => {
      const frontendProfile = environment.host.split('//')[1].split('.')[0];
      console.log(frontendProfile, backendProfile);
      if (!(frontendProfile === 'www' && backendProfile === 'prod')) {
        this.profile = `fe:${frontendProfile} | be:${backendProfile}`;
      }
    });
  }

  public handleClick(label: string): void {
    if (label === 'Log out') {
      this.authService.logOut();
    }
  }

  public toggleMenu(): void {
    this.mobileMenuVisible = !this.mobileMenuVisible;
  }

  public hideMenu() {
    this.mobileMenuVisible = false;
  }

  public removeSpaces(label: string | null | undefined): string {
    if (!label) {
      return '';
    }
    return label.replace(/\s+/g, '');
  }

  public changeLanguage(lang: string): void {
    this.language = lang;
    this.languageService.changeLanguage(lang);
  }

  ngOnDestroy(): void {
    return;
  }

  // public toggleMenu(id: number) {
  //   this.headerData.find(el => el.id === id)!.nestedMenuOpen = !this.headerData.find(el => el.id === id)!.nestedMenuOpen;
  //  }
}
