import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.scss']
})
export class EmailConfirmedComponent {

  constructor(private router: Router) {}

  goBack(): void {
    this.router.navigate(['dashboard']);
  }
}
