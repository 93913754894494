import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IBaseUser, IRole } from '@shared/interfaces/user.interface';
import { Observable, tap } from 'rxjs';

export type IUserProfileData = IBaseUser;

export interface IUserProfileDto {
  // Assuming the birthday is in ISO 8601 format (YYYY-MM-DD)
  data: IUserProfileData;
  photo?: Blob | null;
}

export interface IUserProfileResponse extends IBaseUser {
  // Assuming the birthday is in ISO 8601 format (YYYY-MM-DD)
  email?: string;
  emailVerified?: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  public changeRole(role: IRole): Observable<IUserProfileResponse> {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ role: role }));

    return this.http.patch<IUserProfileResponse>('client', formData);
  }

  public getUserInfo(): Observable<IUserProfileResponse> {
    return this.http.get<IUserProfileResponse>('client/info').pipe(
      tap(user => {
        // TODO: This redirection logic is not the responsibility of the API service.
        // It should be transferred to a component or a corresponding service for managing navigation.
        const currentUrl = this.router.url;

        if (currentUrl === '/email-confirmed') {
          return;
        }
        // Perform the redirect based on some condition
        if (!user.firstName || !user.lastName) {
          // this.router.navigate(['/dashboard']); // Redirect to the dashboard or any other route
        }
      })
    );
  }

  public updateUserProfile(userProfileDto: IUserProfileDto): Observable<IUserProfileResponse> {
    const formData = new FormData();

    formData.append('data', JSON.stringify(userProfileDto.data));

    if (userProfileDto.photo) {
      formData.append('photo', userProfileDto.photo);
    }

    return this.http.patch<IUserProfileResponse>('client', formData);
  }

  public deleteAvatar(): Observable<IUserProfileResponse> {
    return this.http.delete<IUserProfileResponse>('client/avatar');
  }
}
