<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12.1197 13.28C12.0497 13.27 11.9597 13.27 11.8797 13.28C10.1197 13.22 8.71973 11.78 8.71973 10.01C8.71973 8.19998 10.1797 6.72998 11.9997 6.72998C13.8097 6.72998 15.2797 8.19998 15.2797 10.01C15.2697 11.78 13.8797 13.22 12.1197 13.28Z"
    stroke="black"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M18.74 19.88C16.96 21.51 14.6 22.5 12 22.5C9.40001 22.5 7.04001 21.51 5.26001 19.88C5.36001 18.94 5.96001 18.02 7.03001 17.3C9.77001 15.48 14.25 15.48 16.97 17.3C18.04 18.02 18.64 18.94 18.74 19.88Z"
    stroke="black"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
    stroke="black"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
