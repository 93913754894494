export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum Role {
  USER = 'USER',
  OWNER = 'OWNER',
  BUSINESS = 'BUSINESS',
}

export interface IBaseUser {
  id: number;
  first_name: string;
  last_name: string;
  job: string;
  dob: string;
  description: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  birthday: string;
  photoUrl: string | null;
  country: string;
  city: string;
  profession: string;
  aboutYou: string;
  adultsCount: number;
  childrenCount: number;
  infantsCount: number;
  smoke: boolean;
  pets: boolean;
  role: IRole;
}

export interface IUserRole {
  role: Role;
}

export type IRole = keyof typeof Role;

export interface ICroppedImageObject {
  objectUrl?: string | null;
  blob?: Blob | null;
}
