<footer class="footer-wrapper">
  <div class="footer-content">
    <div class="footer-left">
      <div class="logo">
        <img src="./assets/icons/AREAS.svg" alt="AREAS Logo">
      </div>
      <div class="description">
        <p>
          Areas is owned and operated by Nirvana Software N.V., registration number: 00000, Registered address: 55
          Abraham de Veerstraat Curaçao.
        </p>
        <p>
          Contact us at: support@areas.com
        </p>
        <p>
          Areas is authorized and regulated by the Government of Canada and operates under License No. 555/JAZ issued
          to Newfoundland.
          Areas has passed all compliance and is legally authorized to conduct placement of ads with rent and sale.
          Areas has the right to provide additional services when buying or renting.
        </p>
      </div>
    </div>
    <div class="footer-right">
      <div class="menu-row">
        <div *ngFor="let item of footerMenu" class="menu-section" [ngClass]="{ 'contact-us': item.title === 'Contact us' }">
          <div class="menu-title">
            {{ item.title }}
          </div>
          <ul class="menu-items" [ngClass]="{ 'center': item.title === 'Contact us' }">
            <li *ngFor="let el of item.menuItems">
              <a routerLink="{{ el.link }}" *ngIf="!el.type">{{ el.name }}</a>
              <ng-container *ngIf="el.type" [ngSwitch]="el.type">
                <a class="link-with-icon" *ngSwitchCase="'email'" href="mailto:{{ el.link }}">
                  <img src="/assets/icons/mail.svg" alt="mail">
                  <span>{{ el.name }}</span>
                </a>
                <a class="link-with-icon" *ngSwitchCase="'phone'" href="tel:{{ el.link }}">
                  <img src="/assets/icons/phone.svg" alt="phone">
                  <span>{{ el.name }}</span>
                </a>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
