import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

export const WINDOW_TOKEN = new InjectionToken('WINDOW_TOKEN');

export abstract class WindowRef {
  public get nativeWindow(): Window | object {
    throw new Error('Not implemented');
  }
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  public override get nativeWindow(): Window | object {
    return window;
  }
}

export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: any): Window | object {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return {};
}

const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef,
};

const windowProvider: FactoryProvider = {
  provide: WINDOW_TOKEN,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID],
};

export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
