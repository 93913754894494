import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mui-dialog',
  templateUrl: './mui-dialog.component.html',
  styleUrls: ['./mui-dialog.component.scss'],
})
export class MuiDialogComponent implements OnInit {
  public data = this.prepareDataObject();

  private prepareDataObject() {
    const { title } = this.options;

    return {
      title,
    };
  }

  constructor(
    public dialogRef: MatDialogRef<MuiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: { title: string }
  ) {}

  ngOnInit() {
    const matDialogConfig = new MatDialogConfig();

    matDialogConfig.position = { right: `130px`, top: `130px` };

    this.dialogRef.updatePosition(matDialogConfig.position);
  }
}
