import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { Effects } from '@core/store/calendar/effects';
import { appReducer } from '@core/store/redusers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WINDOW_PROVIDERS } from '@shared/services/window.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { UrlInterceptor } from './interceptors/url.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatDialogModule, StoreModule.forRoot(appReducer), EffectsModule.forRoot(Effects)],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    WINDOW_PROVIDERS,
  ],
})
export class CoreModule {}
