import { Injectable } from '@angular/core';
import { selectCalendarSelectedDays } from '@core/store/calendar/calendar.reducer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ICalendarSelectedDay } from '@shared/interfaces/calendar.interface';
import { first, of, switchMap } from 'rxjs';
import * as CalendarActions from './calendar.actions';

@Injectable()
export class CalendarEffects {
  constructor(
    private actions$: Actions,
    private store: Store
  ) {}

  setCalendarDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActions.SetSelectedDay),
      switchMap(({ selectedDay }) =>
        this.store.select(selectCalendarSelectedDays).pipe(
          first(),
          switchMap(({ selectedDays }: { selectedDays: ICalendarSelectedDay[] }) => {
            const action = selectedDays.includes(selectedDay)
              ? CalendarActions.ChangeSelectedDay({ selectedDay })
              : CalendarActions.SetSelectedDays({
                  selectedDays: [...selectedDays, { day: selectedDay }].sort((a, b) => a.day - b.day),
                  selectedDay,
                });
            return of(action);
          })
        )
      )
    )
  );

  setCalendarTimes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActions.ToggleSelectedTime),
      switchMap(({ selectedDay, time }) =>
        this.store.select(selectCalendarSelectedDays).pipe(
          first(),
          switchMap(({ selectedDays }: { selectedDays: ICalendarSelectedDay[] }) => {
            const updatedSelectedDays = selectedDays.map((el: ICalendarSelectedDay) => {
              if (el.day === selectedDay) {
                const updatedTimes = el.time ? [...el.time] : [];
                const timeIndex = updatedTimes.indexOf(time);
                if (timeIndex > -1) {
                  updatedTimes.splice(timeIndex, 1);
                } else {
                  updatedTimes.push(time);
                }
                return { ...el, time: updatedTimes };
              }
              return el;
            });
            return of(CalendarActions.SetSelectedTime({ selectedDays: updatedSelectedDays }));
          })
        )
      )
    )
  );

  removeSelectedDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActions.RemoveSelectedDay),
      switchMap(({ removedDay }) =>
        this.store.select(selectCalendarSelectedDays).pipe(
          first(),
          switchMap(({ selectedDays }: { selectedDays: ICalendarSelectedDay[] }) => {
            let index = 0;
            selectedDays = selectedDays.filter((day, i) => {
              if (removedDay === day.day) {
                index = i;
              }
              return removedDay !== day.day;
            });
            const newSelectedDay = selectedDays.length > 0 ? selectedDays[index - 1]?.day : null;
            return of(CalendarActions.SetSelectedDays({ selectedDays, selectedDay: newSelectedDay }));
          })
        )
      )
    )
  );
}
