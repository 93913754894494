import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class InactivityMonitoringService {
  private inactivityInterval: any = null;
  private inactivityHoursLimit = 3;

  constructor(private storageService: StorageService) {}

  public setLogoutTime(): void {
    const date = new Date();
    date.setTime(date.getTime() + this.inactivityHoursLimit * 60 * 60 * 1000);
    localStorage.setItem('logoutAt', date.getTime().toString());
  }

  public startTimer(): void {
    this.setLogoutTime();

    if (this.inactivityInterval) {
      return;
    }

    this.inactivityInterval = setInterval(() => {
      const logoutAt = Number(localStorage.getItem('logoutAt'));
      if (!logoutAt) {
        this.stopTimer();
        return;
      }
      if (Date.now() >= logoutAt) {
        this.stopTimer();
        this.storageService.clearStorage();
        window.location.reload();
      }
    }, 60 * 1000);
  }

  public stopTimer(): void {
    clearInterval(this.inactivityInterval);
    this.inactivityInterval = null;
  }
}
