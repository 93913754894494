<div class="main">
  <div class="buttons">
    <button class="navbar-btn" [ngClass]="{ active: currentUrl === '/catalog' }" [routerLink]="['', 'catalog']">
      <svg
        class="explore"
        [ngClass]="{ active: currentUrl === '/catalog' }"
        fill="#000000"
        height="24px"
        width="24px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 488.4 488.4"
        xml:space="preserve">
        <g>
          <g>
            <path
              d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6
               s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2
               S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7
               S381.9,104.65,381.9,203.25z" />
          </g>
        </g>
      </svg>
      <span>Explore</span>
    </button>
    <button
      class="navbar-btn"
      [ngClass]="{ active: currentUrl.includes('/dashboard/user/reservations/') }"
      [routerLink]="isLoggedIn ? '/dashboard/user/reservations/favourite' : '/login'">
      <svg
        [ngClass]="{ active: currentUrl.includes('/dashboard/user/reservations/') }"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.8401 4.60999C20.3294 4.099 19.7229 3.69364 19.0555 3.41708C18.388 3.14052 17.6726 2.99817 16.9501 2.99817C16.2276 2.99817 15.5122 3.14052 14.8448 3.41708C14.1773 3.69364 13.5709 4.099 13.0601 4.60999L12.0001 5.66999L10.9401 4.60999C9.90843 3.5783 8.50915 2.9987 7.05012 2.9987C5.59109 2.9987 4.19181 3.5783 3.16012 4.60999C2.12843 5.64169 1.54883 7.04096 1.54883 8.49999C1.54883 9.95903 2.12843 11.3583 3.16012 12.39L4.22012 13.45L12.0001 21.23L19.7801 13.45L20.8401 12.39C21.3511 11.8792 21.7565 11.2728 22.033 10.6053C22.3096 9.93789 22.4519 9.22248 22.4519 8.49999C22.4519 7.77751 22.3096 7.0621 22.033 6.39464C21.7565 5.72718 21.3511 5.12075 20.8401 4.60999Z"
          [attr.stroke]="'#000000'"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <span>Wishlist</span>
    </button>
    <button class="navbar-btn" [ngClass]="{ active: currentUrl === '/login' }" (click)="logInClick()">
      <svg
        width="24"
        height="24"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [ngClass]="{ active: currentUrl === '/login' }">
        <path
          d="M40 42V38C40 35.8783 39.1571 33.8434 37.6569 32.3431C36.1566 30.8429 34.1217 30 32 30H16C13.8783 30 11.8434 30.8429 10.3431 32.3431C8.84285 33.8434 8 35.8783 8 38V42"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M24 22C28.4183 22 32 18.4183 32 14C32 9.58172 28.4183 6 24 6C19.5817 6 16 9.58172 16 14C16 18.4183 19.5817 22 24 22Z"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <span *ngIf="!isLoggedIn">Log in</span>
      <span *ngIf="isLoggedIn">Log out</span>
    </button>
  </div>
</div>
