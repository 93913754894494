<div class="exit-wrapper">
  <div class="exit-box">
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 15C1 15.93 1 16.395 1.10222 16.7765C1.37962 17.8117 2.18827 18.6204 3.22354 18.8978C3.60504 19 4.07003 19 5 19H11.2C12.8802 19 13.7202 19 14.362 18.673C14.9265 18.3854 15.3854 17.9265 15.673 17.362C16 16.7202 16 15.8802 16 14.2V5.8C16 4.11984 16 3.27976 15.673 2.63803C15.3854 2.07354 14.9265 1.6146 14.362 1.32698C13.7202 1 12.8802 1 11.2 1H5C4.07003 1 3.60504 1 3.22354 1.10222C2.18827 1.37962 1.37962 2.18827 1.10222 3.22354C1 3.60504 1 4.07003 1 5"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </div>

  <div class="exit-arrow">
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1L14 5M14 5L10 9M14 5H1"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </div>
</div>
