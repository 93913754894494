import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public changeLanguage(language: string): void {
    const currentParams = { ...this.route.snapshot.params };

    currentParams['lang'] = language;

    this.router.navigate([currentParams]);
  }
}
