import { createAction, props } from '@ngrx/store';
import { TDateOrString } from '@shared/interfaces';
import { ICalendarSelectedDay } from '@shared/interfaces/calendar.interface';

export const ClearCalendarStoreAction = createAction('[Calendar] Clear Store');
export const SetSelectedDay = createAction('[Calendar] Set Selected Day', props<{ selectedDay: TDateOrString }>());
export const ChangeSelectedDay = createAction(
  '[Calendar] Change Selected Day',
  props<{ selectedDay: TDateOrString }>()
);
export const SetSelectedDays = createAction(
  '[Calendar] Set Selected Days',
  props<{ selectedDays: ICalendarSelectedDay[]; selectedDay: TDateOrString }>()
);

export const ToggleSelectedTime = createAction(
  '[Calendar] Toggle Selected Time',
  props<{ selectedDay: TDateOrString; time: string }>()
);
export const SetSelectedTime = createAction(
  '[Calendar] Set Selected Time',
  props<{ selectedDays: ICalendarSelectedDay[] }>()
);

export const RemoveSelectedDay = createAction(
  '[Calendar] Remove Selected Time',
  props<{ removedDay: TDateOrString }>()
);
