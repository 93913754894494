import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { ClickOutsideModule } from '@shared/directives/click-outside/click-outside.module';
import { LogOutComponent } from '@shared/icons/log-out/log-out.component';
import { UserIconComponent } from '@shared/icons/user-icon/user-icon.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    ClickOutsideModule,
    FormsModule,
    TranslocoModule,
    LogOutComponent,
    UserIconComponent,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
