import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class UserIconComponent {
  @HostBinding('style.height') height = '25px';
}
