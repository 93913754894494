export interface FooterMenuI {
  title: string;
  menuItems: { name: string, link: string, type?: string }[]
}

export const FOOTER_MENU: FooterMenuI[] = [
  {
    title: 'Products',
    menuItems: [
      {
        name: 'Categories',
        link: '/categories'
      },
      {
        name: 'Services',
        link: '/sategories'
      },
      {
        name: 'Advertisement',
        link: '/advertisement'
      },
      {
        name: 'Pricing',
        link: '/pricing'
      },
    ]
  },
  {
    title: 'Support',
    menuItems: [
      {
        name: 'Terms & Conditions',
        link: '/terms-and-conditions'
      },
      {
        name: 'Privacy Policy',
        link: '/privacy-policy'
      },
      {
        name: 'Rent & Buy Rules',
        link: '/rent-and-buy-rules'
      },
    ]
  },
  {
    title: 'Contact us',
    menuItems: [
      {
        name: 'support@areas.com',
        link: 'support@areas.com',
        type: 'email'
      },
      {
        name: '+ 38 (067) 555 - 55 -55',
        link: '+ 38 (067) 555 - 55 -55',
        type: 'phone'
      },
    ]
  },
]

export const SOCIAL_BUTTONS = [
  { name: 'twitter', link: '',}, 
  { name: 'facebook', link: '', }, 
  { name: 'instagram', link: '', }, 
  { name: 'youtube', link: '', }
]
