import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[click-outside]'
})
export class ClickOutsideDirective implements OnInit  {
  @Output() outsideClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      document.addEventListener('click', this.onClick.bind(this));
    });
  }

  onClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.outsideClick.emit();
    }
  }
}
