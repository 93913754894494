export interface FooterMenuI {
  title: string;
  menuItems: { name: string; link: string; type?: string }[];
}

export const FOOTER_MENU: FooterMenuI[] = [
  {
    title: 'app.footer.products',
    menuItems: [
      {
        name: 'app.footer.categories',
        link: '/categories',
      },
      {
        name: 'app.footer.services',
        link: '/sategories',
      },
      {
        name: 'app.footer.advertisement',
        link: '/advertisement',
      },
      {
        name: 'app.footer.pricing',
        link: '/pricing',
      },
    ],
  },
  {
    title: 'app.footer.support',
    menuItems: [
      {
        name: 'app.footer.termsAndConditions',
        link: '/terms-and-conditions',
      },
      {
        name: 'app.footer.privacyPolicy',
        link: '/privacy-policy',
      },
      {
        name: 'app.footer..',
        link: '/rent-and-buy-rules',
      },
    ],
  },
  {
    title: 'app.footer.contactUs',
    menuItems: [
      {
        name: 'support@areas.com',
        link: 'support@areas.com',
        type: 'email',
      },
      {
        name: '+ 38 (067) 555 - 55 -55',
        link: '+ 38 (067) 555 - 55 -55',
        type: 'phone',
      },
    ],
  },
];

export const SOCIAL_BUTTONS = [
  { name: 'twitter', link: '' },
  { name: 'facebook', link: '' },
  { name: 'instagram', link: '' },
  { name: 'youtube', link: '' },
];
