import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InactivityMonitoringService } from '@shared/services/inactivity-monitor.service';
import { WINDOW_TOKEN } from '@shared/services/window.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject(WINDOW_TOKEN) private win: Window,
    private inactivityMonitoringService: InactivityMonitoringService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.win.localStorage.getItem('areas-token') as string;

    if (token) {
      this.inactivityMonitoringService.startTimer();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }
}
