<div class="header-wrapper" [ngClass]="{ 'menu-open': mobileMenuVisible }">
  <div class="burger-menu" (click)="toggleMenu()">
    <div class="burger-icon">
      <input type="checkbox" />
      <svg>
        <use xlink:href="#menu" />
        <use xlink:href="#menu" />
      </svg>
    </div>
    <!-- SVG -->
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 56" id="menu">
        <path d="M48.33,45.6H18a14.17,14.17,0,0,1,0-28.34H78.86a17.37,17.37,0,0,1,0,34.74H42.33l-21-21.26L47.75,4" />
      </symbol>
    </svg>
  </div>
  <div [routerLink]="''" class="logo-container">
    <img class="logo" src="assets/images/logo.svg" alt="logo" height="35" width="132" />
    <div class="profile" *ngIf="env">{{ env }}</div>
  </div>
  <div class="menu-title">{{ 'app.header.menu' | transloco }}</div>
  <div class="header-container">
    <div class="auth-block">
      <a routerLink="/{{ userIsLogged ? 'dashboard' : 'login' }}" class="flex-row align-items-center">
        <app-user-icon></app-user-icon>
        <span class="header-text">{{ userIsLogged ? 'Profile' : 'Login' }}</span>
      </a>
      <div class="flex-row exit-icon">
        <ng-container *ngIf="userIsLogged">
          <app-log-out (click)="logOut()"></app-log-out>
          <span class="header-text">Logout</span>
        </ng-container>
        <a routerLink="/auth" *ngIf="!userIsLogged" class="flex-row align-items-center">
          <app-log-out></app-log-out>
          <span class="header-text">Sign Up</span>
        </a>
      </div>
    </div>
    <div class="lang-block">
      <div class="headerData flex-row Language">
        <span>{{ language | uppercase }}</span> <span class="triangle"></span>
        <div class="language-dropdown">
          <div class="buttons">
            <button
              (click)="changeLanguage('en')"
              class="language-dropdown-btn"
              [ngClass]="{ active: language === 'en' }">
              <img src="/assets/icons/us-flag.svg" alt="flag" />
              <span>English</span>
            </button>
            <button
              (click)="changeLanguage('ua')"
              class="language-dropdown-btn"
              [ngClass]="{ active: language === 'ua' }">
              <img src="/assets/icons/ua-flag.svg" alt="flag" />
              <span>Українська</span>
            </button>
            <button
              (click)="changeLanguage('pl')"
              class="language-dropdown-btn"
              [ngClass]="{ active: language === 'pl' }">
              <img src="/assets/icons/pl-flag.svg" alt="flag" />
              <span>Polska</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--    <div *ngIf="userRole$ | async as userRole" class="user-state">-->
    <!--      <span >{{ userRole }}</span>-->
    <!--      <label class="switch">-->
    <!--        <input type="checkbox" [ngModel]="userRole == 'User'" (ngModelChange)="changeUserState(userRole)">-->
    <!--        <span class="slider"></span>-->
    <!--    </label>-->
    <!--    </div>-->

    <!--    <ng-container *ngFor="let data of headerData">-->
    <!--      <ng-container *ngIf="data.name !== 'Country' && data.name !== 'Language'">-->
    <!--&lt;!&ndash;        <a&ndash;&gt;-->
    <!--&lt;!&ndash;          [ngClass]="removeSpaces(data.name)"&ndash;&gt;-->
    <!--&lt;!&ndash;          class="headerData flex-row"&ndash;&gt;-->
    <!--&lt;!&ndash;          *ngIf="data.isVisible"&ndash;&gt;-->
    <!--&lt;!&ndash;          (click)="handleClick(data.name)"&ndash;&gt;-->
    <!--&lt;!&ndash;          [routerLink]="[data.routerLink]">&ndash;&gt;-->
    <!--&lt;!&ndash;          <img *ngIf="data?.icon" [src]="data?.icon" alt="icon" />&ndash;&gt;-->
    <!--&lt;!&ndash;          <span *ngIf="data.name !== 'Language'">{{ data.label | transloco }}</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </a>&ndash;&gt;-->
    <!--      </ng-container>-->
    <!--    <ng-container *ngIf="data.name === 'Language'"> </ng-container>-->
    <!--        </ng-container>-->
    <!--    <button>Help me</button>-->
  </div>
</div>

<div class="mobile-menu" *ngIf="mobileMenuVisible">
  <nav>
    <a *ngIf="!userIsLogged" [routerLink]="'/login'" (click)="hideMenu()">{{ 'app.header.login' | transloco }}</a>
    <a *ngIf="!userIsLogged" [routerLink]="'/auth'" (click)="hideMenu()">{{ 'app.header.signup' | transloco }}</a>
    <a *ngIf="userIsLogged" (click)="logOut()">{{ 'app.header.logout' | transloco }}</a>
  </nav>
</div>
