<div class="header-wrapper" [ngClass]="{ 'menu-open': mobileMenuVisible }">
  <div class="burger-menu" (click)="toggleMenu()">
    <div class="burger-icon">
      <input type="checkbox" />
      <svg>
        <use xlink:href="#menu" />
        <use xlink:href="#menu" />
      </svg>
    </div>
    <!-- SVG -->
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 56" id="menu">
        <path d="M48.33,45.6H18a14.17,14.17,0,0,1,0-28.34H78.86a17.37,17.37,0,0,1,0,34.74H42.33l-21-21.26L47.75,4" />
      </symbol>
    </svg>
  </div>
  <div [routerLink]="''">
    <img class="logo" src="assets/images/logo.svg" alt="logo" height="35" width="132"/>
    <div class="profile" *ngIf="profile">{{ profile }}</div>
  </div>
  <div class="menu-title">Menu</div>
  <div class="header-container">
    <!--    <div *ngIf="userRole$ | async as userRole" class="user-state">-->
    <!--      <span >{{ userRole }}</span>-->
    <!--      <label class="switch">-->
    <!--        <input type="checkbox" [ngModel]="userRole == 'User'" (ngModelChange)="changeUserState(userRole)">-->
    <!--        <span class="slider"></span>-->
    <!--    </label>-->
    <!--    </div>-->

    <ng-container *ngFor="let data of headerData">
      <div
        class="headerData flex-row"
        *ngIf="data.isVisible"
        (click)="handleClick(data.label)"
        [routerLink]="[data.routerLink]">
        <img *ngIf="data?.icon" [src]="data?.icon" alt="icon" />
        <span>{{ data.label }}</span>
      </div>
    </ng-container>
    <!--    <button>Help me</button>-->
  </div>
</div>

<div class="mobile-menu" *ngIf="mobileMenuVisible">
  <nav>
    <a *ngIf="!userIsLogged" [routerLink]="'/login'" (click)="hideMenu()">Login</a>
    <a *ngIf="!userIsLogged" [routerLink]="'/auth'" (click)="hideMenu()">Sign Up</a>
    <a *ngIf="userIsLogged" (click)="handleClick('Log out')">Log out</a>
  </nav>
</div>
