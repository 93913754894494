import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GtmService } from '@core/services/gtm.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Areas-web';
  public showFooter = false;
  public dashboardPage = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gtmservice: GtmService
  ) {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(event => {
        this.showFooter = this.activatedRoute.root.firstChild?.snapshot.data['showFooter'] ?? false;
        this.dashboardPage = event.url.includes('dashboard');
        this.gtmservice.trackPageView(event.urlAfterRedirects);
      });
  }

  ngOnInit(): void {
    this.gtmservice.addGtmScript('GTM-PXLN7783');
  }
}
