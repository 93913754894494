import { Injectable } from '@angular/core';
import { IUserProfileResponse } from '@api/user-api.service';
import { IRole } from '@shared/interfaces/user.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  public userData$!: Observable<IUserProfileResponse | null>;
  private _userData: BehaviorSubject<IUserProfileResponse | null> = new BehaviorSubject<IUserProfileResponse | null>(
    null
  );

  public userRole$!: Observable<IRole | null>;
  private _userRole: BehaviorSubject<IRole | null> = new BehaviorSubject<IRole | null>(null);

  constructor() {
    this.userData$ = this._userData.asObservable();
    this.userRole$ = this._userRole.asObservable();
  }

  public setUserData(user: IUserProfileResponse | null): void {
    this._userData.next(user);
    this.setUserRole(user!.role);
  }

  public setUserRole(role: IRole): void {
    this._userRole.next(role);
  }

  public getUserRole(): IRole | null {
    return this._userRole.value;
  }
}
